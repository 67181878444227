import React, { useState, useEffect } from 'react';

import Helmet from 'react-helmet';

import '@fortawesome/fontawesome-pro/css/all.css';

import Navbar from './Navbar';

// const base64url = require('base64url');

const Header = (props) => {
  // const params = new URLSearchParams(props.location.search);
  // const extraDataEncoded = params ? params.get('ExtraData') : null;
  // const extraDataDecoded = extraDataEncoded
  //   ? base64url.decode(extraDataEncoded)
  //   : null;

  // const extraDataDecodedSplit = extraDataDecoded
  //   ? extraDataDecoded.split('|')
  //   : [];

  const [websiteNavLoading, setWebsiteNavLoading] = useState(true);
  const [websiteNavData, setWebsiteNavData] = useState([]);

  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR =
      R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    var GG =
      G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    var BB =
      B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }

  return (
    <>
      {props.smartfeedCompanyData &&
        props.smartfeedCompanyData.CompanyName &&
        props.smartfeedCompanyData.CompanyName !== 'DefaultCompany' && (
          <div className="top-header-area">
            <header id="header" className="header">
              {props &&
                !props.vacanciesRouteLoading &&
                !props.vacanciesRouteError &&
                props.apiKey && (
                  <>
                    {props.smartfeedCompanyData &&
                      props.smartfeedCompanyData.FullTheme && (
                        <Helmet
                          style={[
                            {
                              cssText: `.page-background-colour {
                          background-colour: ${props.smartfeedCompanyData
                            .FullTheme.BackgroundColour || 'TRANSPARENT'};
                        }
                        .content-background-colour {
                          background-colour: ${props.smartfeedCompanyData
                            .FullTheme.ContentBackColour || 'TRANSPARENT'};
                        }
                        .content-foreground-colour {
                          color: ${props.smartfeedCompanyData.FullTheme
                            .ContentForeColour || '#000000'};
                        }
                        body {
                          font-size: ${props.smartfeedCompanyData.FullTheme
                            .DefaultFontSize || '16px'};
                          font-family: ${props.smartfeedCompanyData.FullTheme
                            .FontFamily ||
                            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"}
                        }
                        .link-colour, a, .top-links__wrapper__container .dropdown-wrapper-nav .dropdown-parentLink.link-item {
                          color: ${props.smartfeedCompanyData.FullTheme
                            .LinkColour || '#337ab7'}
                        }
                        .job-card-title, .job-card-title a, section .vacancies-landing__header__heading, .ctacard.ctacard-application .ctacard__body__title {
                          color: ${props.smartfeedCompanyData.Theme
                            .PrimaryButtonColour || '#9000CB'} 
                        }
                        .job-card-savejob span, .screen-reader {
                          color: ${shadeColor(
                            props.smartfeedCompanyData.Theme
                              .PrimaryButtonColour,
                            20
                          ) || shadeColor('rgb(44, 0, 76)', 20)}
                        }
                        .job-card-meta .job-card-meta-item span {
                          color: ${shadeColor(
                            props.smartfeedCompanyData.Theme
                              .PrimaryButtonColour,
                            20
                          ) || shadeColor('rgb(44, 0, 76)', 20)};
                        }
                        .btn--primary, .nav-action-sm, .btn--action, .vf-careers-site-spacing .btn--action {
                          background: ${props.smartfeedCompanyData.Theme
                            .PrimaryButtonColour || 'rgb(251, 153, 15)'};
                          border: ${'1px solid ' +
                            props.smartfeedCompanyData.Theme
                              .PrimaryButtonColour || 'rgb(251, 153, 15)'};
                          border-radius: 3px;
                          color: ${props.smartfeedCompanyData.Theme
                            .PrimaryButtonTextColour || '#9000CB'}
                        }
                        .btn--primary:hover, .nav-action-sm:hover, .btn--action:hover, .vf-careers-site-spacing .btn--action:hover {
                          background: ${props.smartfeedCompanyData.FullTheme
                            .PrimaryButtonBackgroundHoverColour || '#fff'};
                          color: ${props.smartfeedCompanyData.FullTheme
                            .PrimaryButtonTextHoverColour ||
                            '#000000'} !important;
                        }
                        .btn--secondary, .vf-details ul.vf-Attachments li a.btn-filedownload {
                          background: ${props.smartfeedCompanyData.FullTheme
                            .SecondaryButtonBackgroundColour || 'transparent'};
                          color: ${props.smartfeedCompanyData.FullTheme
                            .SecondaryButtonTextColour || '#9000CB'};
                          border: 1px solid ${props.smartfeedCompanyData
                            .FullTheme.SecondaryButtonBackgroundColour ||
                            '#9000CB'}
                        }
                        .btn--secondary:hover {
                          background: ${props.smartfeedCompanyData.FullTheme
                            .SecondaryButtonBackgroundHoverColour || '#BE7AFF'};
                          color: ${props.smartfeedCompanyData.FullTheme
                            .SecondaryButtonTextHoverColour || '#FFFFFF'}
                          border: 1px solid ${props.smartfeedCompanyData
                            .FullTheme.SecondaryButtonBackgroundHoverColour ||
                            '#BE7AFF'}
                        .table-header-wrapper, #listing-top-single-loading {
                          background: ${props.smartfeedCompanyData.FullTheme
                            .TableHeaderColour || '#9000CB'};
                          color: ${props.smartfeedCompanyData.FullTheme
                            .TableHeaderTextColour || '#ffffff'}
                        }
                        .footer-register-wrap a.btn--action {
                            background: ${shadeColor(
                              props.smartfeedCompanyData.Theme
                                .PrimaryButtonColour,
                              20
                            ) || shadeColor('rgb(251, 153, 15)', 20)}
                        }
                        `,
                            },
                          ]}
                        >
                          <link
                            rel="icon"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="16x16"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="48x48"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="72*72"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="96*96"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="144x144"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="192*192"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="256*256"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="384*384"
                          />
                          <link
                            rel="icon"
                            type="image/png"
                            href={
                              props.smartfeedCompanyData.FullTheme.FavIconUrl ||
                              'favicon.ico'
                            }
                            sizes="512*512"
                          />
                        </Helmet>
                      )}
                    <Navbar props={props} websiteNavData={websiteNavData} />
                  </>
                )}
            </header>
          </div>
        )}
    </>
  );
};

export default Header;
