import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

const Cookies = (props) => {
  const [cookies, setCookie] = useCookies(['cookie-name']);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!cookies.AcceptCookies) {
      setActive(true);
    }
  }, []);

  const onAccept = () => {
    setActive(false);
    setCookie('AcceptCookies', true, {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  };

  return (
    <>
      <div
        id="cookieNotification"
        className={`cookies${active ? ' -active' : '-not_active'}`}
      >
        <div className="container">
          <div className="notification__content">
            <div className="row">
              <div className="col-sm-12 col-md-10">
                <h2 className="notification__title">
                  We use cookies to help improve user experience.
                </h2>
                <p className="notification__message">
                  This includes a range of things such as user personalisation,
                  page analytics and more.{' '}
                  <a
                    target="_blank"
                    title="Privacy Policy"
                    href="https://vacancyfiller.com/privacy-policy/"
                    className="notification__button -primary"
                  >
                    Click here to learn more
                  </a>
                </p>
              </div>
              <div className="col-sm-12 col-md-2">
                <div className="notification__footer">
                  <button
                    id="js-cookie-button-accept"
                    className="notification__button -primary nav-action-sm"
                    onClick={onAccept}
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Cookies.propTypes = {
  siteTitle: PropTypes.string,
};

Cookies.defaultProps = {
  siteTitle: ``,
};

export default Cookies;
