/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { useStatePersist } from 'use-state-persist';

import Header from './Header';
import Footer from './Footer';
import Cookies from './Cookies';
import SEO from './SEO';

//import "../css/base.css"
//import "../css/bootstrap.min.css"
import '../css/addon.min.css';
import '../css/vf-addon.css';
import '../css/theme.css';

// import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import { useCookies } from 'react-cookie';

// import { useStateWithSessionStorage, createSignalsSession } from './Analytics';

// import { v4 as uuidv4 } from 'uuid';

const Layout = (props) => {
  // const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  // const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
  //   'ap-signals-session-id'
  // );
  // const [fingerPrintResult, setFingerPrintResult] = useStateWithSessionStorage(
  //   'ap-signals-session-fingerprint'
  // );

  const [scrolled, setScrolled] = useState(false);

  const onScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    winScroll > 20 ? setScrolled(true) : setScrolled(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  // useEffect(() => {
  //   window.addEventListener('scroll', onScroll);

  //   return async () => {
  //     if (!cookies['ap-signals-user-id']) {
  //       // We recommend to call `load` at application startup.
  //       const fp = await FingerprintJS.load();

  //       // The FingerprintJS agent is ready.
  //       // Get a visitor identifier when you'd like to.
  //       const result = await fp.get();

  //       // This is the visitor identifier:
  //       const visitorId = result.visitorId;

  //       setCookie('ap-signals-user-id', visitorId);
  //       setFingerPrintResult(JSON.stringify(result));
  //     } else if (!sessionIDValue) {
  //       setSessionIDValue(`${uuidv4()}`);
  //     }

  //     createSignalsSession(
  //       cookies['ap-signals-user-id'],
  //       sessionIDValue,
  //       props.siteConfig,
  //       props.location,
  //       fingerPrintResult
  //     );
  //   };
  // }, []);

  const siteConfig = props.siteConfig || {};

  const [fontSize, setFontSize] = useStatePersist('@fontSize', 'normal');
  const [colourContrast, setColourContrast] = useStatePersist(
    '@colourContrast',
    false
  );

  let internalView = false;
  let iFrameView = false;
  let HideCareersLink = false;

  if (props.location && props.location.search) {
    let searchParams = new URLSearchParams(props.location.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === 'iframe') {
        iFrameView = pair[1];
      }
      if (pair[0] === 'HideCareersLink') {
        HideCareersLink = pair[1];
      }
      if (pair[0] === 'IsIntranet') {
        internalView = pair[1];
      }
    }
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div
      className={`main-app-wrap colour-contrast-${colourContrast} font-size-${fontSize} iframe-view-${iFrameView} hide-careers-link-${HideCareersLink}`}
    >
      <SEO
        siteConfig={props.siteConfig}
        apiKey={props.apiKey}
        smartfeedCompanyData={props.smartfeedCompanyData}
        vacanciesRouteLoading={props.vacanciesRouteLoading}
        vacanciesRouteError={props.vacanciesRouteError}
        title={props.title}
        description={props.description}
        internalView={internalView}
      />
      <Header
        location={props.location}
        siteConfig={props.siteConfig || {}}
        vacanciesRouteError={props.vacanciesRouteError}
        vacanciesRouteLoading={props.vacanciesRouteLoading}
        apiKey={siteConfig.apiKey || props.apiKey}
        smartfeedCompanyData={
          siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
        }
        appGatewayURL={props.appGatewayURL}
        siteTitle={data.site.siteMetadata.title}
        setFontSize={setFontSize}
        colourContrast={colourContrast}
        setColourContrast={setColourContrast}
        // trackEvent={props.trackEvent}
        alwaysDisplayHeader={props.alwaysDisplayHeader}
        internalView={internalView}
      ></Header>
      <div className="app-layout">
        <main id="main">{props.children}</main>
      </div>
      {scrolled && (
        <div className="btnTop">
          <button
            title="Back to top"
            className="btn btn--secondary"
            onClick={() => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }}
          >
            <span className="fas fa-chevron-up"></span>
          </button>
        </div>
      )}
      <div className="footer-layout">
        <Footer
          displayJACTA={props.displayJACTA || true}
          siteConfig={props.siteConfig || {}}
          vacanciesRouteError={props.vacanciesRouteError}
          vacanciesRouteLoading={props.vacanciesRouteLoading}
          apiKey={siteConfig.apiKey || props.apiKey}
          smartfeedCompanyData={
            siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
          }
          appGatewayURL={props.appGatewayURL}
          setFontSize={setFontSize}
          colourContrast={colourContrast}
          setColourContrast={setColourContrast}
          jobAlertsPage={props.jobAlertsPage}
          // trackEvent={props.trackEvent}
          internalView={internalView}
        />
        {/* <Cookies
          siteConfig={props.siteConfig || {}}
          vacanciesRouteError={props.vacanciesRouteError}
          vacanciesRouteLoading={props.vacanciesRouteLoading}
          apiKey={siteConfig.apiKey || props.apiKey}
          smartfeedCompanyData={
            siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
          }
          trackEvent={props.trackEvent}
          internalView={internalView}
        /> */}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
